<template>
    <main class="c-app-content is-sidebar-tiny">
        <div class="row">
            <div class="col-12" style="justify-content: space-around; flex-direction: row; display: flex;">
                <div class="c-btn-mobile js-btn-mobile mt-2"><i class="fa fa-bars"></i> Mở rộng menu</div>
            </div>
            <div class="col-lg-2 c-sidebar-main">
                <div class="c-sidebar-menu">
                    <ul>
                        <li v-if="isTW() && clients.length || isUsaid() && clients.length">
                            <label>Chỉ tiêu của nhà thầu chính</label>
                            <ul>
                                <router-link v-for="item of clients" :key="item.id" :to="`/manager/kpi/view/${item.id}`" v-slot="{href, isExactActive}">
                                    <li :class="[isExactActive ? 'active' : '']">
                                        <a :href="href" :title='item.name' >
                                            <i v-if='kpis[item.id] && kpis[item.id] > 0' class="fas fa-check-circle text-success"></i>
                                            <i v-else class="fas fa-circle"></i>
                                            {{item.short_name}} {{item.id}}
                                        </a>
                                    </li>
                                </router-link>
                            </ul>
                        </li>
                        <li v-if='isPrime()'>
                            <label>Thiết lập chỉ tiêu cho thầu chính</label>
                            <ul>
                                <router-link to="/manager/kpi/index" v-slot="{href, isExactActive}">
                                    <li :class="[isExactActive ? 'active' : '']">
                                        <a :href="href">
                                            {{$store.state.client.short_name}}
                                            ({{$store.state.client.indicators ? $store.state.client.indicators.length : 0}} chỉ số)
                                            <i v-if='kpis[$store.state.client.id] && kpis[$store.state.client.id] > 0' class="small fas fa-check-circle text-success"></i>
                                            <!--<i v-else class="small fas fa-circle"></i>-->
                                        </a>
                                    </li>
                                </router-link>
                            </ul>
                        </li>
                        <li v-if="isPrime() && clients.length">
                            <label>Thiết lập chỉ tiêu cho thầu phụ</label>
                            <ul>
                                <router-link v-for="item of clients" :key="item.id" :to="`/manager/kpi/${item.id}`" v-slot="{href, isExactActive}">
                                    <li :class="[isExactActive ? 'active' : '']">
                                        <a :href="href" :title='item.name' >
                                            {{item.short_name}}
                                            ({{item.indicators ? item.indicators.length : 0}} chỉ số)
                                            <i v-if='kpis[item.id] && kpis[item.id] > 0' class="small fas fa-check-circle text-success"></i>
                                            <!--<i v-else class="small fas fa-circle"></i>-->
                                        </a>
                                    </li>
                                </router-link>
                            </ul>
                        </li>
                        <li v-if="isSub()">
                            <label>Đơn vị chủ quản</label>
                            <ul>
                                <router-link v-for="item of clients" :key="item.id" :to="`/manager/kpi/view/${item.id}`" v-slot="{href, isExactActive}">
                                    <li :class="[isExactActive ? 'active' : '']">
                                        <a :href="href" :title='item.name' >
                                            <i v-if='kpis[item.id] && kpis[item.id] > 0' class="fas fa-check-circle text-success"></i>
                                            <i v-else class="fas fa-circle"></i>
                                            {{item.short_name}} {{item.id}}
                                        </a>
                                    </li>
                                </router-link>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-10">
                <router-view></router-view>
            </div>
        </div>
    </main>
</template>

<script>
    import $ from 'jquery';
    import role from '@/mixins/Role';
    import moment from 'moment';

    export default {
        mixins: [role],
        data: function () {
            return {
                client: this.$store.state.client,
                clients: [],
                kpis: [],
                indicators: {},
            };
        },
        methods: {
            load() {
                if (this.isPrime()) {
                    this.$service.get('/kpi/get-sub', {
                        params: {
                            quarter: moment().quarter(),
                            year: moment().format("YYYY"),
                        }
                    }).then(response => {
                        this.clients = response.data.clients;
                        this.kpis = response.data.kpis;
                    });
                } else if(this.isTW() || this.isUsaid()) {
                    this.$service.get('/kpi/get-tw-usaid', {
                        params: {
                            quarter: moment().quarter(),
                            year: moment().format("YYYY"),
                        }
                    }).then(response => {
                        this.clients = response.data.clients;
                        this.kpis = response.data.kpis;
                    });
                } else {
                    this.$service.get('/kpi/get-prime', {
                        params: {
                            quarter: moment().quarter(),
                            year: moment().format("YYYY"),
                        }
                    }).then(response => {
                        this.clients = response.data.clients;
                        this.kpis = response.data.kpis;
                    });
                }
            },
        },
        mounted: async function () {
            this.indicators = {};
            for (let code in this.$params.indicator) {
                let indicator = this.$params.indicator[code];
                if (!indicator.manual) {
                    continue;
                }
                this.indicators[code] = indicator;
            }
            this.load();
            this.$bus.$on('reloadKpi', this.load);

            //js expand menu setting
            $('.js-btn-mobile').on('click', function (e) {
                e.preventDefault();
                var sidebar = $('.c-sidebar-main');
                if (sidebar.hasClass('active')) {
                    sidebar.removeClass('active').slideUp();
                } else {
                    sidebar.addClass('active').slideDown();
                }
            });

            //close setting menu in mobile
            $('.c-sidebar-menu a').on('click', function (e) {
                var sidebar = $('.c-sidebar-main');
                var viewportGlobal = $(window).width();
                if (e.target == this && viewportGlobal < 768) {
                    sidebar.removeClass('active').slideUp();
                }
            });
        },
        beforeDestroy: function () {
            this.$bus.$off('reloadKpi');
        },
    }
</script>